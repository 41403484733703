
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
@Component({
  filters: {
    filterTimeList (value: Array<{ startTime: string; endTime: string }>) {
      return value.map((item) => {
        return item.startTime + '-' + item.endTime
      }).join(',')
    }
  }
})
@KeepAlive
export default class AppointProjectList extends Vue {
  private loading = false
  private info = {
    itemName: '',
    parkId: '',
    itemStatus: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private tableData = []

  get projectList () {
    return this.$store.state.projectList || []
  }

  created (): void {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.appointManage.selectAppointItemByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  changeStatus (row: { itemId: string; itemStatus: string }) {
    this.$axios.post(this.$apis.appointManage.updateAppointItemStatus, {
      itemId: row.itemId,
      itemStatus: row.itemStatus
    }).then(() => {
      this.$message.success('状态设置成功')
      this.loadData()
    }).catch(() => {
      this.loadData()
    })
  }

  onAdd () {
    this.$router.push('/appointproject/add')
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'appointProjectDetail',
      params: { id }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.appointManage.deleteAppointItem, {
        itemId: id
      }).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.onSearch()
      })
    })
  }
}
